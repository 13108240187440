export const TIPO_DOCUMENTO = {
    RELATORIO_VISTORIA: 0,
    RELATORIO_FOTOGRAFICO: 1,
    PLANTA_ATUAL: 2,
    PLANTA_ADEQUACOES: 3,
    PLANO_ADEQUACOES: 4,
    LAUDO_VALOR_LOCATICIO: 5,
  };

export const TIPO_ARQUIVO = {
    IMAGEM: 0,
    DOCUMENTO: 1,
  };