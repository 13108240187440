const TIPO_PROPONENTE = [
  {
    value: 1,
    label: "Proprietário"
  },
  {
    value: 2,
    label: "Representante legal"
  }
];

module.exports = {
  TIPO_PROPONENTE: TIPO_PROPONENTE
};
